<template>
  <div class="settings-page">
    <div class="container">
      <div class="user">
        <div class="user__icon">
          <img src="@/assets/img/svg/user-default.svg" alt="" />
        </div>

        <div class="user__data">
          <div class="user__name">Username</div>

          <div class="user-id">
            <div class="user-id__title">
              {{ $t("user-id") }}
            </div>

            <div class="user-id__number">adsffasd123t254123213dfds</div>
          </div>
        </div>
      </div>
      <div class="settings-wrap">
        <div class="setting-sound" @click="isSoundOn = !isSoundOn">
          <div class="setting-sound__icon">
            <img
              src="@/assets/img/svg/icon-sound.svg"
              alt=""
              style="width: 60%"
            />
          </div>

          <div class="setting-sound__title">
            {{ $t("settings-sound") }}
            <span v-show="isSoundOn"> {{ $t("settings-on") }}</span>
            <span v-show="!isSoundOn"> {{ $t("settings-off") }}</span>
          </div>
        </div>

        <div class="setting-sound" @click="isMusicOn = !isMusicOn">
          <div class="setting-sound__icon">
            <img
              src="@/assets/img/svg/icon-music.svg"
              alt=""
              style="width: 40%"
            />
          </div>

          <div class="setting-sound__title">
            {{ $t("settings-music") }}
            <span v-show="isMusicOn"> {{ $t("settings-on") }}</span>
            <span v-show="!isMusicOn"> {{ $t("settings-off") }}</span>
          </div>
        </div>
      </div>

      <div class="settings-language language">
        <div class="language__title">
          {{ $t("settings-lang") }}
        </div>

        <div class="select">
          <ul class="select-list">
            <transition name="slide-fade">
              <li class="select-list__item">
                {{ currentLang }}
              </li>
            </transition>
          </ul>

          <div class="select__arrows">
            <button type="button" class="arrow arrow--left"></button>
            <button type="button" class="arrow arrow--right"></button>
          </div>
        </div>
      </div>

      <div class="settings-social">
        <div
          class="settings-social__wrap"
          v-for="(item, index) in socialList"
          :key="index"
          :class="{ disabled: item.isConnected }"
        >
          <a href="#" class="settings-social__link"></a>
          <div class="settings-social__icon">
            <img
              :src="require(`@/assets/img/svg/logo-${item.icon}.svg`)"
              alt=""
            />
          </div>

          <div class="settings-social__text">
            <span v-show="item.isConnected"> {{ $t("social-connected") }}</span>
            <span v-show="!item.isConnected"> {{ $t("social-connect") }}</span>
          </div>
        </div>
      </div>

      <button class="page-btn page-btn--dark" @click="logOut">
        {{ $t("sign-out") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Profile",
  data: () => ({
    isSoundOn: true,
    isMusicOn: true,
    currentLang: "English",
    socialList: [
      {
        icon: "apple",
        isConnected: true,
      },
      {
        icon: "facebook",
        isConnected: false,
      },
      {
        icon: "gmail",
        isConnected: false,
      },
      {
        icon: "vk",
        isConnected: false,
      },
    ],
  }),
  methods: {
    ...mapMutations({
      unsetUser: "user/unsetUser",
    }),
    logOut() {
      this.unsetUser();
      this.$router.push("/sign-in");
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
